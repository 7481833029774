// pop-up

$('.pop-up-button').click(function(e){
  e.preventDefault();
  $('.pop-up').addClass('open');

});

$('.pop-up .close').click(function(){
  $('.pop-up').removeClass('open');
});

// pop-up-2

$('.pop-up-2-button').click(function(e){
  e.preventDefault();
  $('.pop-up-2').addClass('open');

});

$('.pop-up-2 .close').click(function(){
  $('.pop-up-2').removeClass('open');
});

// sale pop-up

$('.sale-popup-btn').click(function(e){
  e.preventDefault();
  $('.sale-popup').addClass('open');
});

$('.sale-popup .close').click(function(){
  $('.sale-popup').removeClass('open');
});

// init menu
function initMenu() {
    var $this = $(this);
    var mobNav = $('.menu__container');

    if ( $this.hasClass('is-active') ) {
        $this.removeClass('is-active');
        mobNav.removeClass('active');
        $('body').css('overflow', 'auto');
    } else {
        $this.addClass('is-active');
        mobNav.addClass('active');
        $('body').css('overflow', 'hidden');
    }
}

// getting height of header and give it to the menu
function menuSpacing() {
    $headerNav = $('.main-header__menu');
    $headerHeight = $('.main-header__menu').outerHeight() + 30;

    if ( $headerHeight ) {
        $('.menu__container .nav').css('padding-top', $headerHeight);
    }
}

// init main slider
function initSlider() {
    var mySwiper = new Swiper ('.swiper-container', {
        loop: true,
        autoplay: {
            delay: 5000,
        },
        // Navigation arrows
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    })
}

// init slider for tiles
function tilesSlider() {
    var mySwiper = new Swiper('.tiles__slider', {
        loop: true,
        autoplay: {
            delay: 5000,
        },
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
        },
        on: {
            slideChange: function() {
                $('.tiles__slider-index').text((this.realIndex + 1));
            }
        },
    });
}


$(function() {
    menuSpacing();
    initSlider();
    tilesSlider();
    $(document).on('click', '.hamburger', initMenu);
});




// mobile menu tigger
$('.mobile-toggle').click(function () {
  $('.mobile-nav nav ul.nav').slideToggle(0);
  $(this).toggleClass('open');
  $('.main-header').toggleClass('open_bg');
  $('.order-mobile-btn').css('display', 'none');
  $('.order-mobile-btn-2').css('display', 'none');
  $('body').toggleClass('overflow');
  //$('.main-header__logo').css('background', 'none');
}); // end slide toggle
$('.mobile-nav').removeClass('main-header__logo');


$(window).resize(function () {
  if ($(window).width() > 414) {
      $('.mobile-nav nav ul.nav').removeAttr('style');
  }
}); // end resize


// mobile menü click funktion

$('.main-header__left ul li a.nav-link').click(function () {

  $('.mobile-nav nav ul.nav').slideToggle(200);
  setTimeout(function () {
    $('.mobile-toggle').removeClass('open');
    $('.main-header').toggleClass('open_bg');
    $('body').removeClass('overflow');
  }, 400);
});





// sticky header
$(window).scroll(function () {
  if ($(window)
    .scrollTop() > 100 ) {
    $('.main-header')
    .addClass('sticky');
    //$('.order-mobile-btn').html('<a href="#" class="btn btn-third">order now</a>');
    $('.order-mobile-btn').css('display', 'block');
    $('.order-mobile-btn-2').css('display', 'block');
  } else {
    $('.main-header').removeClass('sticky');
    //$('.order-mobile-btn').html('');
    $('.hero__body a').removeClass('btn-third');
}
});




//Display order btn

function myFunction(x) {
    if (x.matches) { // If media query matches

      $(window).scroll(function () {
          if ($(window)
            .scrollTop() > 100 ) {
              $('.order-mobile-btn').css('display', 'block');
              $('.order-mobile-btn-2').css('display', 'block');
              //$('.order-mobile-btn').html('<a href="#" class="btn btn-third">order now</a>');
              // $('.hero__body a').removeClass('btn');
              // $('.hero__body a').removeClass('btn-secondary');
          } else {
            //$('.order-mobile-btn').html('');
            $('.hero__body a').removeClass('btn-third');
            $('.order-mobile-btn').css('display', 'none');
            $('.order-mobile-btn-2').css('display', 'none');
            $('.hero__body a').addClass('btn');
            $('.hero__body a').addClass('btn-secondary');
          }
      });
    }
}

//Open pop-up if mobile order button gets clicked

$('.order-mobile-btn').click(function(event) {
  $('.pop-up').addClass('open');
});
$('.order-mobile-btn-2').click(function(event) {
  $('#pops-2').addClass('open');
});

var x = window.matchMedia("(max-width: 1024px)")
myFunction(x) // Call listener function at run time
x.addListener(myFunction) // Attach listener function on state changes


// anchor jump

function addMargin() {
    window.scrollTo(0, window.pageYOffset - 130);
}

window.addEventListener('hashchange', addMargin);

// Delays jumps link destinations in mobile menu by 100 milliseconds
function delay (URL) {
    setTimeout( function() { window.location = URL }, 100 );
}
